export const Prices = [
    {
        _id: 0,
        name: 'Tk 0 to 499',
        array: [0, 499]
    },
    {
        _id: 1,
        name: 'Tk 500 to 999',
        array: [500, 999]
    },
    {
        _id: 2,
        name: 'Tk 1000 to 1499',
        array: [1000, 1500]
    },
    {
        _id: 3,
        name: 'Tk 1500 to 1999',
        array: [1500, 1999]
    },
    {
        _id: 4,
        name: 'Tk 2000 to 2499',
        array: [2000, 2499]
    },
    {
        _id: 5,
        name: 'TK 2500 or more',
        array: [2500, 9999]
    },

]